<template>
  <div class="main">
    <div class="row mx-4 my-4">
      <EmployeeCard></EmployeeCard>
      <div class="col-xl-9 col-lg-8 ps-4 userSettings">
        <ProfileProgress></ProfileProgress>
        <div class="mt-4 settingsBody rounded">
          <div class="pt-3 pb-0 ps-4">
            <strong>Afiliación</strong>
          </div>
          <hr />
          <div>
            <form class="settingForm" @submit.prevent="sendBasic">
              <div class="mb-3">
                <label for="Dependents" class="form-label"
                  >Número de Hijos</label
                >
                <input
                  type="number"
                  class="form-control"
                  name="Dependents"
                  :min="minDependents"
                  :max="maxDependents"
                  v-model="Dependents"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="typeOfHousing" class="form-label"
                  >Tipo de Vivienda</label
                >
                <select
                  class="form-select text-capitalize"
                  name="typeOfHousing"
                  required
                  v-model="typeOfHousing"
                >
                  <option
                    v-for="typeOfHousing in typeOfHousingList"
                    v-bind:value="typeOfHousing"
                    :key="typeOfHousing"
                    >{{ typeOfHousing.toLowerCase().replace("_", " ") }}</option
                  >
                </select>
              </div>
              <div class="mb-3">
                <label for="stratum" class="form-label">Estrato</label>
                <select
                  class="form-select text-capitalize"
                  name="stratum"
                  required
                  v-model="stratum"
                >
                  <option
                    v-for="stratum in stratumList"
                    v-bind:value="stratum"
                    :key="stratum"
                    >Estrato {{ stratum }}</option
                  >
                </select>
              </div>
              <div class="mb-3">
                <label for="scholarship" class="form-label">Escolaridad</label>
                <select
                  class="form-select text-capitalize"
                  name="scholarship"
                  required
                  v-model="scholarship"
                >
                  <option
                    v-for="scholarship in scholarshipList"
                    v-bind:value="scholarship"
                    :key="scholarship"
                    >{{ scholarship.toLowerCase().replace("_", " ") }}</option
                  >
                </select>
              </div>
              <div class="mb-3">
                <label for="sports" class="form-label"
                  >Deportes que Practica</label
                >
                <input
                  type="text"
                  class="form-control"
                  name="sports"
                  minlength="1"
                  :maxlength="maxSports"
                  v-model="sports"
                />
              </div>
              <div class="mb-3">
                <label for="healthyActivities" class="form-label"
                  >Actividades Saludables</label
                >
                <input
                  type="text"
                  class="form-control"
                  name="healthyActivities"
                  minlength="1"
                  :maxlength="maxHealthyActivities"
                  v-model="healthyActivities"
                />
              </div>
              <div class="mb-3">
                <label for="freeTime" class="form-label"
                  >¿Qué Hace en el Tiempo Libre?</label
                >
                <input
                  type="text"
                  class="form-control"
                  name="freeTime"
                  minlength="1"
                  :maxlength="maxFreeTime"
                  v-model="freeTime"
                />
              </div>
              <div class="mb-3 d-flex justify-content-between">
                <div class="flex-fill me-4">
                  <label for="drink" class="form-label">¿Bebe?</label>
                  <select
                    class="form-select text-capitalize"
                    name="drink"
                    v-model="drink"
                    required
                  >
                    <option value="0">No</option>
                    <option value="1">Sí</option>
                  </select>
                </div>
                <div class="flex-fill">
                  <label for="smoke" class="form-label">¿Fuma?</label>
                  <select
                    class="form-select text-capitalize"
                    name="smoke"
                    v-model="smoke"
                    required
                  >
                    <option value="0">No</option>
                    <option value="1">Sí</option>
                  </select>
                </div>
              </div>
              <div class="mb-3">
                <label for="allergy" class="form-label"
                  >Alergias o Enfermedades</label
                >
                <input
                  type="text"
                  class="form-control"
                  name="allergy"
                  minlength="1"
                  :maxlength="maxAllergy"
                  v-model="allergy"
                />
              </div>
              <button type="submit mb-3" class="btn btn-primary shadow-primary">
                Actualizar
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <Legal></Legal>
  </div>
</template>

<style lang="css" scoped>
.settingsBody {
  background-color: var(--secondary-bg);
}
.settingForm {
  margin: auto;
  padding-bottom: 20px;
  width: 50%;
  min-width: 280px;
}
@media only screen and (max-width: 990px) {
  .userSettings {
    padding: 0 !important;
  }
  .userSettings {
    margin-block: 30px;
  }
}
</style>

<script>
import Legal from "@/components/layout/Legal";
import EmployeeCard from "@/views/employee/EmployeeCard";
import ProfileProgress from "@/views/employee/ProfileProgress";

import Storage from "@/modules/storage";
import Swal from "sweetalert2";

export default {
  name: "Employee Profile Edit Additional",
  components: { Legal, EmployeeCard, ProfileProgress },
  data() {
    return {
      Dependents: "",
      minDependents: 0,
      maxDependents: 1,
      typeOfHousing: "",
      typeOfHousingList: [],
      stratum: "",
      stratumList: [],
      scholarship: "",
      scholarshipList: [],
      sports: "",
      maxSports: 1,
      healthyActivities: "",
      maxHealthyActivities: 1,
      freeTime: "",
      maxFreeTime: 1,
      drink: "",
      smoke: "",
      allergy: "",
      maxAllergy: 1
    };
  },
  beforeCreate() {
    const store = new Storage();

    store.getData("employee", this.$route.params._id).then(employeeData => {
      store.getData("branch", employeeData.branch).then(branchData => {
        store.getData("city", branchData.city).then(cityData => {
          localStorage.setItem(
            "currentLocation",
            "Empleados / Perfiles / " +
              cityData.name +
              " / " +
              employeeData.name +
              " / Datos Adicionales"
          );
        });
      });
    });
  },
  mounted() {
    const store = new Storage();

    store.getData("schema").then(schemaList => {
      schemaList.forEach(schema => {
        if (schema.store === "employee") {
          this.minDependents = schema.data.Dependents.min;
          this.maxDependents = schema.data.Dependents.max;
          this.maxSports = schema.data.sports.max;
          this.maxFreeTime = schema.data.freeTime.max;
          this.maxAllergy = schema.data.allergy.max;
          this.maxHealthyActivities = schema.data.healthyActivities.max;
          this.typeOfHousingList = schema.data.typeOfHousing.enum;
          this.stratumList = schema.data.stratum.enum;
          this.scholarshipList = schema.data.scholarship.enum;
        }
      });
    });
    store.getData("employee", this.$route.params._id).then(employee => {
      this.Dependents = employee.Dependents;
      this.typeOfHousing = employee.typeOfHousing;
      this.stratum = employee.stratum;
      this.scholarship = employee.scholarship;
      this.sports = employee.sports;
      this.healthyActivities = employee.healthyActivities;
      this.freeTime = employee.freeTime;
      this.allergy = employee.allergy;
      if (employee.drink === true) {
        this.drink = "1";
      } else if (employee.drink === false) {
        this.drink = "0";
      }
      if (employee.smoke === true) {
        this.smoke = "1";
      } else if (employee.smoke === false) {
        this.smoke = "0";
      }
    });
  },
  methods: {
    sendBasic() {
      Swal.fire({
        icon: "warning",
        title: "Actualizando información",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        stopKeydownPropagation: true,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          setInterval(() => {
            const content = Swal.getContent();
            if (content) {
              const b = content.querySelector("b");
              if (b) {
                b.textContent = Swal.getTimerLeft();
              }
            }
          }, 100);
        }
      });
      const store = new Storage();
      let drink = false;
      let smoke = false;
      if (this.drink === "1") {
        drink = true;
      }
      if (this.smoke === "1") {
        smoke = true;
      }
      const data = {
        _id: this.$route.params._id,
        Dependents: this.Dependents,
        typeOfHousing: this.typeOfHousing,
        stratum: this.stratum,
        scholarship: this.scholarship,
        sports: this.sports,
        healthyActivities: this.healthyActivities,
        freeTime: this.freeTime,
        allergy: this.allergy,
        drink: drink,
        smoke: smoke
      };
      store
        .updateData("employee", [data], true, "optima")
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Datos Actualizados",
            text: "Todos los datos han sido sincronizados"
          }).then(() => {
            this.$router.go();
          });
        })
        .catch(error => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Error al actualizar los datos",
            text:
              "Los datos han sido guardados en su computador o celular, una vez se restablesca la conexión, los datos se sincronizarán con el servidor"
          }).then(result => {
            this.$router.go();
          });
        });
    }
  }
};
</script>
